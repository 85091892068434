.servizi{
  .rettangolo{
    padding-left: 35px;
    display:none;
    width: 100%;
    height: 450px;
    background: linear-gradient(to right bottom, #1C5D81 50%, $grey 50.1%);
    z-index: -2;
    @include respond-to(md){
      display:block;
    }

  }
  h2{
    width:220px;
    margin:0 auto;
    @include respond-to(md){
      width:250px;
    }

    background: $white;
    text-align: center;
    padding: 20px 10px;
    border-radius: 6px;
    position:relative;
    // box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
    z-index: 1;

    &:after{
      content:'';
      position:absolute;
      height: 20px;
      width:20px;
      background-color: $white;
      bottom:-10px;
      right:calc(50% - 10px);
      transform: rotate(45deg) skew(10deg, 10deg);
      border-radius: 3px;
      z-index: -1;
    }
  }
  .container{
    &.mobile{
      display:block;
      margin-bottom: 30px;
      @include respond-to(md){
        display: none;
      }
    }
  }
  .cards{
    margin: 0 5px;
    @include respond-to(md){
      margin: -225px 0 -20px 0 ;
    }
    .card{
      border:none;
      border-radius: 6px;
      margin:25px auto -15px;
      max-width: 406px;
      padding: 30px 50px;
      min-height: 513px;
      z-index: 9;
      @include respond-to(sm){
            max-height: 534px;
      }
      @include respond-to(md){
        margin:30px auto ;
      }
      span{
        font-size: 16px;
        font-weight: bold;
        margin:10px auto;
      }
      img{
        width:115px;
        margin: 0 auto;
      }
      h3{
        color:$white;
        background-color: $blue;
        text-align: center;
        border-radius: 6px;
        font-size: 28px;
        margin: 45px 0 20px 0;
      }
      ul{
        margin-left: 40px;
        li{
          margin-bottom: 10px;
          line-height: 22px;

        }
      }
    }
  }
  .bottom-servizi{
    @include respond-to(md){
      display:block;
    }
    background-color: $blue;
    display:none;
    z-index: -4;
    .triangle{
      img{
        @include respond-to(lg){
          margin-top: -194px;
        }

        margin-top: -150px;
        width: 100vw;
      }
    }
  }
}
