//------------------------------------------------------------layout
$hamb-w: 30px;
$hamb-h: 30px;
$hamb-l-h: 3px;
$hamb-l-w: 20px;

header{
	// GENERIC STYLES
	&.main-header{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: $zindex-max - 1;
		background: $white;
		padding: 20px 0;
		transform: translateY(-80px);
		transition: transform .4s;
		// LIST STYLE
		.logo{
			padding-left: 20px;
			height: 30px;
			img{
				height: 100%;
			}
		}
		&.v_nav-list{
			padding-right: 20px;
			.nav-menu{
				text-align: right;
				ul{
					display: block;
					margin: 0;
					padding: 0;
					display: none;
					@include respond-to(md) {
						display: block;
					}
					li{
						display: inline-block;
						color: $blue;
						margin: 0 10px;
						font-size: 800;
						opacity: 0.7;
						transition: opacity .2s;
						&:last-child{
							margin: 0 0 0 10px;
						}
						a{
							color: inherit;
							font-size: inherit;
						}
						&:hover{
							opacity:1.3;

						}
					}

				}
			}
			&.navopen {
				display: block;
				@include basetransition($timing-fast);
				@include respond-to(md) {
					display: none;
				}
			}
			.hamburger-cont{
				display: inline-block;
				@include respond-to(md) {
					display: none;
				}
			}
		}
		// HAMBURGER STYLE
		&.v_hamburger{
			.hamburger-cont{
				display: inline-block;
			}
			.nav-menu{
				text-align: right;
				ul{
					display: none;
				}
			}
		}
		&.scrolled{
			transform: translateY(0px);
		}
	}
	.hamburger-cont{
  	position: relative;
		width: $hamb-w;
		height: $hamb-h;
		// margin-bottom:10px;

		&.navopen{
			.hamburger{
				.middle-line{
					background: transparent;
					opacity: 0;
					@include basetransition($timing-fast);
				}
				&:after{
					transform: rotate(-45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
				&:before{
					transform: rotate(45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
			}
		}
		.hamburger{
			cursor: pointer;
			position: relative;
			background: transparent;
			border: 0;
	    width: 100%;
  		height: 100%;
			&.two-lines_mod{
				.middle-line{
					display: none;
				}
				&:after{
					top: 10px;
			    right: 0;
				}
				&:before{
					bottom: 0px;
			    right: 0;
				}
			}
			&.three-lines_mod{
				.middle-line{
					right: 5px;
				}
				&:after{
					top: 10px;
			    right: 5px;
				}
				&:before{
					bottom:7px;
			    right: 5px;
				}
			}
			.middle-line{
				height: $hamb-l-h;
				width: $hamb-l-w;
				position: absolute;
				background: $blue;
				@include basetransition($timing-fast);
			}
			&:after{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $blue;
				position: absolute;
		    @include basetransition($timing-fast);
			}
			&:before{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $blue;
				position: absolute;
		    @include basetransition($timing-fast);
			}
		}
	}
}

// MOBILE MENU STYLE AND VARIATIONS
.mobile-menu{

	position: fixed;
	z-index: 99;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: $white;
	color: $blue;
	display: none;
	@include basetransition($timing-fast);
	.row{
 		min-height: 100vh;
	}
	&.center_mod{
		ul{
			text-align: center;
		}
	}
	&.left_mod{
		ul{
			text-align: left;
		}
	}
	&.right_mod{
		ul{
			text-align: right;
		}
	}
	ul{
		padding: 0;
		li{
			display: block;
			color: $blue;
			font-size: 36px;
			line-height: 66px;
			font-weight: bold;
			a{
				color: inherit;
				font-size: inherit;
			}
		}
	}
	&.v_nav-list{
		&.navopen {
			display: block;
			@include respond-to(md) {
				display: none;
			}
		}
	}
	&.v_hamburger{
		&.navopen {
			display: block;
		}
	}
	&.navopen {
		@include basetransition($timing-fast);
	}
}

//------------------------------------------------------------layout###
