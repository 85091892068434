.contatti{
  padding: 60px 0 0 0;

  h2{
    width:220px;
    margin:30px auto 50px;
    @include respond-to(md){
      width:250px;
    }

    background: $white;
    text-align: center;
    padding: 20px 10px;
    border-radius: 6px;
    position:relative;
    // box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
    z-index: 1;

    &:after{
      content:'';
      position:absolute;
      height: 20px;
      width:20px;
      background-color: $white;
      bottom:-10px;
      right:calc(50% - 10px);
      transform: rotate(45deg) skew(10deg, 10deg);
      border-radius: 3px;
      z-index: -1;
    }
  }

  img{
    @include respond-to(md){
      display:inline-block;
        margin-right: 20px;
    }

    width: 39px;
    // display:block;

  }
p,a{
  @include respond-to(md){
    display:inline-block;
    margin-top: -27px;
  }
    color:$white;
    line-height: 22px;
    display:block;
    vertical-align: middle ;

    letter-spacing: 2px;

  }
  ul{

    margin-bottom: 50px;
  @include respond-to(md){
    margin-top: 60px;
    margin-bottom:0;
    margin-left: 35px;
  }
  }
  li{
    list-style:none;
    margin-bottom: 15px;
  }
 hr{
   width: 80px;
   background-color: rgba($white, 0.5);
   @include respond-to(sm){
     width:0;
     display:none;
   }
 }
 @include respond-to(sm){
   padding-left: 35px;
 }
}
a{
  &:hover{
    opacity: 0.5;
  }
}


#map{

  height: 250px;
  width: 100%;
  @include respond-to(sm){
    height: 250px;
    width: 100%;
  }
  text-align: right;
  border-radius: 8px;

  margin-left: 35px;
  margin-bottom:60px;
  }
