//---------------------------------------------sections
$sc-height: 100vh;

.section{
  position: relative;
  &.height-mod_100{
    min-height: $sc-height;
  }
  &.row_mod{
    .row{
      min-height: $sc-height;
    }
  }
  &.bk-img_mod{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.bk-color_mod{
    background-color: $grey;
  }
  &.padding_mod_1{
    padding: 90px 0;
  }
  &.padding_mod_2{
    padding: 140px 0;
  }
  &.padding_mod_3{
    padding: 180px 0;
  }
  &.margin_mod_1{
    padding: 40px 0;
  }
  &.margin_mod_2{
    padding: 60px 0;
  }
  &.margin_mod_3{
    padding: 90px 0;
  }
  &.blue{
    background-color: $blue;
  }
  &.grey{
    background-color: $blue;
    @include respond-to(md){
      background-color: $grey;
    }

  }
}


.padding-top_mod_1{
  padding-top:30vh;
}
