//---------------------------------------------colors
$white: #fff;
$black: #000;

$color_text_base: #185C83;
$color_link_base:#4aa3df;
$body_bg: $white;

// $blackcustom: #4A4A4A;
$grey: #F3F3F3;
$grey-dark: #D8D8D8;
// $grey2: #C4C4C4;
// $grey3: #9B9B9B;
// $red: #E21E2F;
$blue: #185C83;
// $bluedark: #134E89;
// $orange: #F18B00;
// $yellow: #F9C213;
// $yellow2: #E4B633;
$green: #9FBABB;
// $green2: #3F997F;
// $lilla: #C18ABC;
// $magenta: #CF196F;
// $acqua: #43A1AF;

$input_placeholder_color: $grey;

// FONT COLORS
@mixin maincolor() {
	color: $blue;
}
@mixin secondcolor() {
	color: $white;
}
// @mixin secondcolor() {
// 	color: $black;
// }

// BACK COLORS
@mixin maincolorback() {
	background-color: $grey;
}
@mixin secondcolorback() {
	background-color: $blue;
}
// @mixin thirdcolorback() {
// 	background-color: $black;
// }

// GENERIC COLORS
@mixin blackback() {
	background-color: $black;
}
@mixin mainblack() {
	color: $black;
}


$colormap: (
	red: $red,
	blue: $blue,
	green: $green
);

@mixin colorclasses($type) {
	@if $type == background {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      background-color: $color;
	    }
	  }
	}
	@if $type == text {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      color: $color;
	    }
	  }
	}
	@if $type == border {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      border-color: $color;
	    }
	  }
	}
}


//---------------------------------------------end colors
