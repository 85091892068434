.footer {
	padding: 0 0 0  0;
	background-color: $blue;
	color:$white;
	.rights{
		p{
		text-align: center;
		font-size: 14px;
		text-transform: uppercase;
			strong{
				color:$white;
				margin-right: 30px;
			}
		}
		@include respond-to(sm){
			margin-left: 55px;

			p{
				text-align: left;
				
			}
		}
	}

	ul{
		margin-right:20px;

		display:none;
		@include respond-to(md){
			display: block;

		}
		text-align: right;
		li{
			display: inline-block;
			margin: 0 5px;
			color:rgba($white, 0.5);
			transition: color .3s;
			font-size: 14px;
			&:hover{
				transition: color .3s;
				color: $white;
				a{
					color:$white;
				}
			}
			a{
				transition: color .3s;
				color:rgba($white, 0.5);
			}
		}
	}
	.credits{
		margin-top: 20px;
		background: $grey-dark;
		padding: 20px 0;

		p,a{
			text-transform: uppercase;
			color: $blue;
			font-size: 12px;

		}
		a{
			font-weight: 600;
		}
	}
	@include respond-to(sm){

		padding: 40px 0 0 0 ;
	}
}
