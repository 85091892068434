//-------------------------------------------------------inputs and selects
%form_field_default {
	display: block;
	width: 100%;
	height: $input_height;
	padding: $input_offset;
	font-size: $input_font_size;

	color: $input_text_color;
	box-shadow: inset 0 0 0 1px $input_border_color;
	border:none;
	border-radius: $input_radius;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder {
		color: $input_placeholder_color;
	}
	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}
}

%form_field_default_hover {
}

%form_field_default_active {
	box-shadow: 0 0 0 1px $input_border_color_active;
}

.form_cell {
	&.hidden_field {
		display: none;
	}

	&.form_cell_v1_mod {
	}
}

.form_cell_title {

	&.hline_hide_mod {
		display: none;
	}
	&.form_v1_mod {
	}
}

.form_field_wrap {
	position: relative;

	&.form_v1_mod {
	}
}

.form_field:not(.select_rediz_chosen) {
	&.default_mod {
		@extend %form_field_default !optional;

		&:focus {
			@extend %form_field_default_active !optional;
		}

		&:hover {
			@extend %form_field_default_hover !optional;
		}

		&.textarea_mod_1 {
		}
	}
}

//-------------------------------------------------------inputs and selects###
