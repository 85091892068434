.formazione{
  padding: 60px 0;
  color: $white;
  @include respond-to(md){
    padding: 80px 0;
  }
  .citazioni-slider{
    display:none;
    @include respond-to(md){
      display:block;
    }
    p{
      font-weight: 600;
      font-size: 20px;
      display: block;
      line-height: 24px;
      span{
        display: block;
        font-size: 14px;
        opacity: 0.5;
      }
    }
    .slick-dots{
      li{
        cursor: pointer;
        height: 6px;
        width: 6px;
        background: $white;
        text-decoration: none;
        list-style: none;
        display: inline-block;
        margin: 30px 6px;
        border-radius: 100%;
        opacity: 0.5;
        font-size: 0;
        &.slick-active{
          opacity: 1;
        }
      }
    }
  }
  h1{
    padding: 10px 30px;
    border: 2px solid $white;
    border-radius: 3px;
    margin-bottom: 50px;
    @include respond-to(md){
      font-size:32px;
      font-weight: bold;

      padding: 5px 0px;
      margin: 70px 0;
    }

  }
  .esperienze{
    .single-esperienza{
      margin: 0 0 50px 0;
      .anno{
        @include respond-to(md){
          text-align: right;
          margin-bottom: 0px;
        }
        margin-bottom: 20px;
        text-align: center;
        font-size: 42px;
        font-weight: 500;
        line-height: 42px;
        margin-right: 20px;
      }
    }
  }
}
