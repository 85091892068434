.opening{

  .col-grey{
    background: $grey;
  }
  .col-green{
    background: $green;
  }
  .triangle{
    position:relative;
    text-align: center;
    margin:0 auto;
    background-color: red;
    width:100%;
    background: linear-gradient(90deg, $green 50%, $grey 50%);
    overflow:hidden;
    vertical-align: baseline !important;
    img{
      width:100%;
    }
    .logo-opening{
      position:absolute;
      bottom:50%;
      right: 0;
      left: 0;
      margin:0 auto;
      width:200px;
      animation: frombottom 1.2s;
      overflow: hidden;
      @include respond-to(md){
        width:280px;
      }
    }
  }
  .first-part{
    display: none;
    margin-top:50px;
    @include respond-to(sm){
      display:block;
    }
    &.mobile{
      margin:20px 0;
      display:block;
      .img-container{
        width: 100%;
        text-align: center;
        .logo-opening{
          width:220px;

        }
      }

      @include respond-to(sm){
        display:none;
      }
    }
  }
  .second-part{
    // min-height: 80vh;

    .opening-pic{
      margin-bottom: -8px;
      margin-top:30%;
      @include respond-to(md){
        margin:0 auto;
        width: 490px;
        border:0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: -8px;
      }
    }
    .opening-box{
      padding:30px 0;
      text-align: center;
      @include respond-to(md){
        text-align: left;
        margin-left: 60px;
        margin-top: 25%;
        width: 65%;
        padding-bottom: 40px;
      }
      h2{
        letter-spacing: 7px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;

      }
      p{
        margin: 30px 20px;
        line-height: 20px;


        @include respond-to(md){
          margin:0;
          margin-top: 30px;
          width: 80%;
        }
      }
    }
  }
}
@keyframes frombottom{
  0% {transform: translateY(100%);}
  100% {transform: translateY(0px);}
}
