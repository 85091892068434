//---------------------------------------------global values
$height_header: 10px;
$height_footer: 10px;

$gl_indent: 3rem;
$gl_radius:.5rem;

$ls-base: 0.5px;

$fh: 100vh;
$hh: 50vh;
$hh2: 25vh;
$pfh: 30px 0 30px 0;
$phh: 80px 0 80px 0;
//---------------------------------------------global values###

//---------------------------------------------transitions
$timing-slow: 0.6s;
$timing-fast: 0.3s;
$timing-vfast: 0.15s;

@mixin basetransition($timing: $timing-slow) {
  transition: $timing ease;
}
//---------------------------------------------transitions###

//---------------------------------------------columns
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
//---------------------------------------------columns###

//---------------------------------------------z-index
$zindex-max: 99999 !default;
//---------------------------------------------z-index###


//---------------------------------------------forms
$input_height:4rem;
$input_offset:0 2rem;
$input_font_size: $font-size-base;
$input_border_color:#ccc;
$input_border_color_active:$black;
$input_placeholder_color: $black;
$input_text_color:$black;
$input_radius:$gl_radius;
//---------------------------------------------forms###
