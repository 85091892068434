//------------------------------------------------------------responsive
// MEDIAQUERIES
$breakpoints: (
  xs: 480px,
  sms: 600px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

//------------------------------------------------------------end responsive
